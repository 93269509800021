<template>
  <div
    class="app flex-row align-items-center"
    :class="retornarClasseBackground()"
  >
    <div class="container">
      <b-row class="justify-content-center row-login">
        <a href="../../"
          ><img
            src="./../assets/img/logo-union-login.png"
            alt="Logo Union"
            class="logo-union"
        /></a>
        <b-col
          md="4"
          v-if="!objEstaVazio(manutencao)"
          id="container-aviso-manutencao"
        >
          <p class="info-manutencao">
            <i class="fa fa-cog"></i> Sistema em manutenção
          </p>
        </b-col>
        <b-col md="8">
          <b-card-group>
            <b-card v-if="conviteValido" no-body class="card-login p-4">
              <b-card-body>
                <b-form>
                  <b-row>
                    <b-col
                      class="text-center"
                      style="border-right: 1px solid #ddd"
                    >
                      <p><b>Escolha uma senha!</b></p>
                      <b-input-group class="mb-4">
                        <b-input-group-prepend>
                          <b-input-group-text>
                            <i class="icon-lock"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <input
                          type="password"
                          id="password"
                          class="form-control"
                          maxlength="40"
                          placeholder="Insira uma senha"
                          v-model="password"
                        />
                        <b-input-group-append>
                          <b-input-group-text>
                            <i
                              class="fa fa-eye"
                              id="bpassword"
                              @click="showPassword"
                            ></i>
                          </b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                      <b-input-group class="mb-4">
                        <b-input-group-prepend>
                          <b-input-group-text>
                            <i class="icon-lock"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <input
                          type="password"
                          id="cpassword"
                          class="form-control"
                          maxlength="40"
                          placeholder="Confirmar senha"
                          v-model="cpassword"
                        />
                        <b-input-group-append>
                          <b-input-group-text>
                            <i
                              class="fa fa-eye"
                              id="bcpassword"
                              @click="showCPassword"
                            ></i>
                          </b-input-group-text>
                        </b-input-group-append>
                      </b-input-group>
                      <span
                        v-show="!requisitoSenhaConfirmacao"
                        class="requisitoNOk"
                      >
                        Senhas diferentes</span
                      >
                      <p><b>Requisitos para uma senha segura:</b></p>
                      <div
                        style="
                          width: 60%;
                          text-align: left;
                          display: inline-table;
                        "
                      >
                        <i
                          class="fa fa-times-circle-o"
                          style="color: #ff4500"
                        ></i>
                        <span
                          :class="
                            requisitoSenhaMinimoCaracteres
                              ? 'requisitoOk'
                              : 'requisitoNOk'
                          "
                        >
                          Use pelo menos 8 caracteres</span
                        ><br />
                        <i
                          class="fa fa-times-circle-o"
                          style="color: #ff4500"
                        ></i>
                        <span
                          :class="
                            requisitoSenhaCaracteresMaiusculos
                              ? 'requisitoOk'
                              : 'requisitoNOk'
                          "
                        >
                          Use caracteres maiúsculos</span
                        ><br />
                        <i
                          class="fa fa-times-circle-o"
                          style="color: #ff4500"
                        ></i>
                        <span
                          :class="
                            requisitoSenhaCaracteresMinusculos
                              ? 'requisitoOk'
                              : 'requisitoNOk'
                          "
                        >
                          Use caracteres minúsculos</span
                        ><br />
                        <i
                          class="fa fa-times-circle-o"
                          style="color: #ff4500"
                        ></i>
                        <span
                          :class="
                            requisitoSenhaNumeros
                              ? 'requisitoOk'
                              : 'requisitoNOk'
                          "
                        >
                          Insira números</span
                        ><br />
                        <i
                          class="fa fa-times-circle-o"
                          style="color: #ff4500"
                        ></i>
                        <span
                          :class="
                            requisitoSenhaCaracteresEspeciais
                              ? 'requisitoOk'
                              : 'requisitoNOk'
                          "
                        >
                          Insira caracteres especiais</span
                        >
                      </div>
                    </b-col>
                    <b-col class="text-center">
                      <p><b>Confirme seus dados:</b></p>
                      <b-input-group class="mb-3">
                        <b-input-group-prepend>
                          <b-input-group-text>
                            <i class="icon-user"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Nome"
                          v-model="nome"
                        />
                      </b-input-group>
                      <b-input-group class="mb-3">
                        <b-input-group-prepend>
                          <b-input-group-text>
                            <i class="fa fa-envelope-o"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Email"
                          v-model="email"
                          readonly
                        />
                      </b-input-group>
                      <b-input-group class="mb-3">
                        <b-input-group-prepend>
                          <b-input-group-text>
                            <i class="fa fa-whatsapp"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="WhatsApp"
                          v-model="whatsapp"
                          v-mask="'(##) #####-####'"
                        />
                      </b-input-group>
                      <p>
                        <b>Informe sua data de nascimento</b><br />
                        <i>(opcional)</i>
                      </p>
                      <b-input-group
                        class="mb-3"
                        style="width: 180px; display: -webkit-inline-box"
                      >
                        <b-input-group-prepend>
                          <b-input-group-text>
                            <i class="fa fa-calendar"></i>
                          </b-input-group-text>
                        </b-input-group-prepend>
                        <input
                          type="date"
                          class="form-control"
                          placeholder="Nome"
                          v-model="datanascimento"
                        />
                      </b-input-group>
                    </b-col>
                  </b-row>
                  <b-row class="group" id="row-btn-login">
                    <b-col cols="3">
                      <b-button
                        v-on:click="confirmar()"
                        class="px-4 btn-login"
                        :disabled="
                          !requisitoSenhaMinimoCaracteres ||
                          !requisitoSenhaCaracteresMaiusculos ||
                          !requisitoSenhaCaracteresMinusculos ||
                          !requisitoSenhaNumeros ||
                          !requisitoSenhaCaracteresEspeciais ||
                          !requisitoSenhaConfirmacao ||
                          !this.password ||
                          !this.cpassword
                        "
                        >Confirmar</b-button
                      >
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-body>
            </b-card>
          </b-card-group>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<style scoped>
.app {
  background-size: cover;
  background-repeat: no-repeat;
}

.background-padrao {
  background-image: url("./../assets/img/fundo_login.png");
}

.background-manutencao {
  background-image: url("./../assets/img/manutencao.png");
  background-position: center;
}

.row-login {
  flex-direction: column;
  align-items: center;
}
.card {
  background-color: transparent;
  border: none;
}
.card-body {
  padding: 0 !important;
}
.card-login {
  background-color: #fff;
  margin-top: 3rem;
  padding: 1em !important;
  border-radius: 1em;
}
.input-group-text,
.btn-item-empresa {
  border-bottom-left-radius: 50px;
  border-top-left-radius: 50px;
  border-color: orangered;
}
.input-group-text i {
  color: orangered;
}
.input-group input,
.btn-item-empresa {
  border-bottom-right-radius: 50px;
  border-top-right-radius: 50px;
  border-color: orangered;
}
#row-btn-login {
  justify-content: center;
  display: flex;
  margin-top: 30px;
}
.btn-login,
.btn-voltar {
  border-color: rgba(255, 68, 0, 0.897);
  background-color: orangered;
  color: white;
  border-radius: 50px;
  padding: 0 1em;
  width: 100%;
  height: 30px;
}
.btn-login:hover,
.btn-voltar:hover {
  background-color: rgba(255, 68, 0, 0.808);
}
.btn-voltar {
  width: auto;
}
.estilo-empresa {
  font-size: 1.2rem;
  color: #fff;
}
.margin-bottom {
  margin-bottom: 15px !important;
}

#container-aviso-manutencao ~ .col-md-4 .card-login {
  margin-top: 0 !important;
}

#container-aviso-manutencao .fa-cog {
  margin-right: 10px;
}

.info-manutencao {
  margin-top: 1rem;
  font-size: 24px;
  text-align: center;
  background-color: lightblue;
  border-radius: 20px;
  padding-top: 2px;
  padding-bottom: 2px;
}

#container-aviso {
  margin-top: 1.5em;
  padding: 2px;
  border-radius: 16px;
  border: 1px #fff solid;
  backdrop-filter: blur(10px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.info-aviso {
  margin-bottom: 1rem;
  font-size: 24px;
  text-align: center;
  color: #fff;
}

#container-aviso a {
  color: #fff;
  border-radius: 16px;
  border: 1px #fff solid;
  padding: 5px 15px;
  font-size: 20px;
  margin-bottom: 1em;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#container-aviso a img {
  width: 1.2em;
  margin-right: 0.2em;
}

.requisitoOk {
  color: #000;
}

.requisitoNOk {
  color: #ff0000;
}
</style>

<script>
import Autenticar from "../services/autenticar";
import Usuario from "../services/usuario";
import Vue from "vue";
import VueTheMask from "vue-the-mask";
Vue.use(VueTheMask);

export default {
  name: "cadastro",
  data() {
    return {
      nome: "",
      email: "",
      whatsapp: "",
      password: "",
      cpassword: "",
      datanascimento: "",
      manutencao: {},
      carregandoChecagemManutencao: true,
      conviteValido: false,
      code: "",
    };
  },
  beforeMount() {
    this.loadCadastro(this.$route.params.code);
  },
  mounted() {
    this.validarSistemaEmManutencao();

    if (this.$store.state.ui.msg && this.$store.state.ui.msg_type) {
      this.$helper.showMsg(
        this.$store.state.ui.msg,
        this.$store.state.ui.msg_type
      );
      this.$store.commit("ui/MSG", null);
      this.$store.commit("ui/MSG_TYPE", null);
    }
  },
  computed: {
    objEstaVazio() {
      return (obj) => {
        return Object.keys(obj).length === 0;
      };
    },
    retornarClasseBackground() {
      return () => {
        if (!this.objEstaVazio(this.manutencao)) {
          return "background-manutencao";
        }

        return "background-padrao";
      };
    },
    requisitoSenhaMinimoCaracteres() {
      if (this.password.length > 0 && this.password.length < 8) return false;
      return true;
    },
    requisitoSenhaCaracteresMaiusculos() {
      if (
        this.password.length == 0 ||
        this.password.toLocaleLowerCase() != this.password
      )
        return true;
      return false;
    },
    requisitoSenhaCaracteresMinusculos() {
      if (
        this.password.length == 0 ||
        this.password.toLocaleUpperCase() != this.password
      )
        return true;
      return false;
    },
    requisitoSenhaNumeros() {
      if (this.password.length > 0 && !/\d/.test(this.password)) return false;
      return true;
    },
    requisitoSenhaCaracteresEspeciais() {
      if (
        this.password.length > 0 &&
        !/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(this.password)
      )
        return false;
      return true;
    },
    requisitoSenhaConfirmacao() {
      if (
        this.password != "" &&
        this.cpassword != "" &&
        this.password != this.cpassword
      )
        return false;
      return true;
    },
  },
  methods: {
    loadCadastro(code) {
      Usuario.getDadosCadastro(code)
        .then((response) => {
          if (response.data.status == 200) {
            this.code = code;
            this.nome = response.data.data.first_name;
            this.email = response.data.data.email;
            this.whatsapp = response.data.data.whatsapp;
            this.conviteValido = true;
          }
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
          this.$router.push("../login");
        });
    },
    confirmar() {
      if (
        this.nome == "" ||
        this.whatsapp == "" ||
        this.password == "" ||
        this.cpassword == ""
      ) {
        this.$helper.showMsg("Dados obrigatórios não informados.", "warning");
        return;
      }
      if (this.whatsapp.length < 14) {
        this.$helper.showMsg("Número whatsapp incompleto", "warning");
        return;
      }

      let data = {
        code: this.code,
        nome: this.nome,
        whatsapp: this.whatsapp,
        password: this.password,
        datanascimento: this.datanascimento,
      };
      Usuario.confirmarUsuario(data)
        .then((response) => {
          if (response.data.status == 200) {
            this.$helper.showMsg(
              "Usuário confirmado com sucesso. Faça login.",
              "success"
            );
            this.$router.push("../login");
          } else this.$helper.showMsg(response.data.msg, response.data.type);
        })
        .catch((e) => {
          this.$helper.showErrorResponse(e);
        });
    },
    validarSistemaEmManutencao() {
      this.carregandoChecagemManutencao = true;

      Autenticar.validarManutencao()
        .then((response) => {
          if (response.data && response.data.manutencao) {
            this.manutencao = response.data.manutencao;
          }
        })
        .catch((error) => {
          this.manutencao = {};
          console.error(error);
        })
        .finally(() => {
          this.carregandoChecagemManutencao = false;
        });
    },
    showPassword() {
      var password = document.getElementById("password");
      var bpassword = document.getElementById("bpassword");
      if (password.type === "password") {
        password.type = "text";
        bpassword.className = "fa fa-eye-slash";
      } else {
        password.type = "password";
        bpassword.className = "fa fa-eye";
      }
    },
    showCPassword() {
      var cpassword = document.getElementById("cpassword");
      var bcpassword = document.getElementById("bcpassword");
      if (cpassword.type === "password") {
        cpassword.type = "text";
        bcpassword.className = "fa fa-eye-slash";
      } else {
        cpassword.type = "password";
        bcpassword.className = "fa fa-eye";
      }
    },
  },
};
</script>
